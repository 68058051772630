<template>
  <div class="snowfall-container">
    <div class="music-player">
      <audio ref="audio" :src="audioSrc" autoplay></audio>
  </div>
  </div>
</template>
  
<script>
import axios from '../API/axios';
import Swal from "sweetalert2";
import moment from 'moment';

export default {
  name: "LoginPage",
  data() {
    return {
      musicSourch:"../assets/haiDunia.mp3",
      audioSrc: require('@/assets/okgas.mp3')
    };
  },
  methods: {
    tryPlayMusic() {
      console.log("Trying to play music");
      const audioElement = this.$refs.audio;

      // Coba langsung memutar audio saat komponen di-mount
      audioElement.play().then(() => {
        console.log("Music started playing automatically");
        this.playError = false;
      }).catch((error) => {
        console.error("Error trying to play music automatically:", error);
        this.playError = true;
      });
    },
    playMusic() {
      console.log("Play button clicked");
      const audioElement = this.$refs.audio;

      // Pemutaran manual jika gagal otomatis
      audioElement.play().then(() => {
        console.log("Music started playing");
        this.playError = false;
      }).catch((error) => {
        console.error("Error trying to play music:", error);
        this.playError = true;
      });
    }
  }
};
</script>