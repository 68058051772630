<template>
  <div @click="tryPlayMusic">
    <audio ref="audio" :src="audioSrc" autoplay></audio>
    <p v-if="playError">Klik di sini untuk memutar musik.</p>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      playError: false,
      audioSrc: require('@/assets/okgas.mp3')
    };
  },
  mounted() {
    // Kami tidak lagi mencoba memutar otomatis di sini, karena harus menunggu interaksi pengguna
  },
  methods: {
    tryPlayMusic() {
      console.log("Trying to play music after user interaction");
      const audioElement = this.$refs.audio;

      // Coba memutar audio setelah interaksi pengguna (klik)
      audioElement.play().then(() => {
        console.log("Music started playing");
        this.playError = false;
      }).catch((error) => {
        console.error("Error trying to play music:", error);
        this.playError = true;
      });
    }
  }
}
</script>

<style scoped>
/* Tambahkan gaya yang diperlukan di sini */
</style>
